import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [2, 3, 4],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi, I'm Ting Shao
    </Text>
    <br />
Software Engineer at <a href="https://official.junyiacademy.org/">JunyiAcademy</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      